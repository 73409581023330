import React,{useState,useEffect,useCallback} from 'react'
import classes from './VideoList.module.css'
import { GetVideosByCategory } from '../Services/ApiFunction'
// import image from '../Assets/image.png'
import {useNavigate} from 'react-router-dom'
import Lottie from 'lottie-react'
import loader from '../Animations/loader3.json'

const VideoList = ({category,number,id}) => {

    const [videos,setVideos] = useState(null);
    const [loading,setLoading] = useState(false)
    
    const fetchData=useCallback(
        async() => {
            try{
                setLoading(true)
                const res = await GetVideosByCategory(category);
                console.log(res)
                if(number && id){
                    let filteredVideos = res;
                        if (number && id) {
                            filteredVideos = res.filter((video) => video.id !== id);
                        }

                        // Shuffle the filtered videos and pick 5 random ones
                        const shuffledVideos = filteredVideos.sort(() => 0.5 - Math.random());
                        const selectedVideos = shuffledVideos.slice(0, 5);

                        setVideos(selectedVideos); 
                        setLoading(false);
                        return;
                }
                setVideos(res)
            }
            catch(err){
                console.log(err);
                // toast.error(err.response.message)
            }
            setLoading(false);
        },
        [category,id],
    )


    console.log(videos)

    const navigate = useNavigate()

    useEffect(()=>{
        fetchData()
    },[fetchData])
    
    const handlePlayClick=(id)=>{
        console.log('handleplay')
        navigate(`/video/${id}`)
      }

     

  return (

    <>
    {loading ? 
    <>
    <Lottie
        animationData = {loader}
        style={{height:'20rem'}}
    />
    </>
    :
    <div className={classes.container}>
    {
        videos && videos.map((video,index)=>(
            <>
            <div className={classes.card}>
                <div className={classes.card_img}>
                <img src={video?.thumbnail_url} alt="" className={classes.img} loading = "lazy" onClick={()=>handlePlayClick(video.id)} />
                <button className={classes.btn} onClick={()=>handlePlayClick(video.id)}> <i className='pi pi-play' ></i>Play</button>
                </div>
                <p className={classes.name}>{video?.name}</p>
            </div>
            </>
        ))
    }
    </div>
    }
    
    </>
  )
}

export default VideoList
