import React,{useCallback,useState,useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import classes from './Carousel.module.css'
import {useNavigate} from 'react-router-dom'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination,Autoplay } from 'swiper/modules';
// import image from '../Assets/image.png'
// import image2 from '../Assets/image2.png'
import { GetVideos } from '../Services/ApiFunction';

const Carousel = () => {

  const [videos,setVideos] = useState();
  const navigate = useNavigate()

  const fetchData=useCallback(
    async() => {
      try{
        const res = await GetVideos();
        const shuffled = res.sort(() => 0.5 - Math.random());
        const selectedVideos = shuffled.slice(0, 6); // Pick first 5-6 items
        setVideos(selectedVideos);
      }
      catch(err){

      }
    },
    [],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleImageClick=(id)=>{
    navigate(`/video/${id}`)
  }


  return (
    <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={false}
        pagination={{
          clickable: true,
        }}
        autoplay={{
        delay: 2000, // Time in milliseconds between slides
        disableOnInteraction: false, // Keeps autoplay running even after interaction
      }}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
      {
        videos && 
        videos.map((video,idx)=>(
          
          <SwiperSlide className={classes.slider} key={idx}>
          <img src={video?.thumbnail_url} className={classes.img} loading = "lazy" onClick={()=>handleImageClick(video?.id)} />

         
        </SwiperSlide>
          
        ))
      }
        
        
      </Swiper>
  )
}

export default Carousel
