// const base_url = 'http://localhost:1919'
const base_url = ``
// const base_url = `https://lifestyyl.com`
export {base_url}


const get_categories = `${base_url}/video/get-category`
export {get_categories}

const get_videos_by_category = `${base_url}/video/get-videos`
export {get_videos_by_category}

const get_all_videos = `${base_url}/video/videos`
export {get_all_videos};

const get_video_by_id = `${base_url}/video/get-video`
export {get_video_by_id};