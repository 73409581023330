import React,{useState,useCallback,useEffect} from 'react'
import { GetCategories } from '../Services/ApiFunction';
import Navbar from '../Components/Navbar';
import Carousel from '../Components/Carousel';
import CategoryDivCarousel from '../Components/CategoryDivCarousel';
import classes from './Home.module.css'
import Lottie from 'lottie-react';
import loader from '../Animations/loader3.json'


const Home = () => {

  const [categories,setCategories] = useState([]);
  const [loading,setLoading] = useState(false);

  const fetchCategories = useCallback(
    async() => {
      setLoading(true);
      try{
        const res = await GetCategories();
        if (res && Array.isArray(res.categories)) {
          setCategories(res.categories);
        }
      }
      catch(err){
        console.log(err);
      }
      setLoading(false)
    },
    [],
  )

  useEffect(()=>{
    fetchCategories()
  },[fetchCategories])

  console.log(categories)

  useEffect(()=>{
      window.scrollTo({
        top: 0, // Scroll to the top
        behavior: 'smooth', // Adds a smooth scrolling effect
      });
    },[])

  return (
    <>
    {
      loading ? <Lottie 
        animationData={loader}
      /> :
    
    <div>
    <Navbar Categories={categories}/>
    <div className={classes.main}>
    <div className={classes.carousel}>
    <Carousel/>
    </div>
    {/* <div className={classes.category_div}>
      <CategoryDivCarousel category="Explore"/>
        </div> */}
    {
      Array.isArray(categories) && categories.length>0 && 
      categories.map((category,idx)=>{
        const catName = category?.cat_name || 'Yoga';
        return(
        <div className={classes.category_div} key={idx}>
      <CategoryDivCarousel category={catName || 'Yoga'}/>
        </div>
      ) 

      })
    }
    
    </div>
    </div>
  }
    </>
  )
}

export default Home
