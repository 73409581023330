import React,{useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom'
import classes from './Navbar.module.css'
import SidebarComponent from './SidebarComponent';
import logo from '../Assets/image.png'


const Navbar = ({Categories}) => {

  const [showSideBar,setShowSideBar] = useState(false);

  const handleToggleSideBar=()=>{
    setShowSideBar(!showSideBar)
  }

  return (
    <div className={classes.container}>
      <nav className={classes.nav}>
        <div className={classes.logo}>
          <Link to={`/`}>
            {/* <p>Lifestyyl</p> */}
            <img src={logo} alt="Logo" style={{height:"3rem"}} />
          </Link>
        </div>
        <div className={classes.categories}>
          {
            Categories &&  Categories.map((category,idx)=>(
              <div className={classes.category}>
                <Link to={`/videos/${category.cat_name}`}>{category.cat_name}</Link>
              </div>
            ))
          }
        </div>
        <div className={classes.icon}>
        <i className="pi pi-sliders-h" onClick={handleToggleSideBar} style={{cursor:'pointer'}}></i>
        </div>
      </nav>
      <SidebarComponent visible = {showSideBar} handle ={handleToggleSideBar} categories={Categories}/>
      <ToastContainer/>
    </div>
  )
}

export default Navbar
