import React from 'react'
import { Sidebar } from 'primereact/sidebar';
import classes from './Sidebar.module.css';
import {Link} from 'react-router-dom'


const SidebarComponent = ({visible,handle,categories}) => {
  return (
    <Sidebar visible={visible} position="left" onHide={handle } className={classes.sidebar}>
    <div>
      <p className={classes.title}>Video Categories</p>
      <ul className={classes.list}>
        {
          categories && categories.map((category, index) =>(
            <Link to={`/videos/${category?.cat_name}`}>
            <li className={classes.list_item}>{category?.cat_name}</li>
            </Link>
          ))
        }
      </ul>
    </div>
    </Sidebar>
  )
}

export default SidebarComponent
