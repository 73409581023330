import { Pagination,Autoplay} from 'swiper/modules';
import React, { useRef, useState,useCallback,useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import image from '../Assets/image.png'
// import image2 from '../Assets/image2.png'
import classes from './CategoryDivCarousel.module.css'
import {useNavigate} from 'react-router-dom'
import { GetVideosByCategory } from '../Services/ApiFunction'
import Lottie from 'lottie-react'
import loader from '../Animations/loader3.json'


import 'swiper/css';
import 'swiper/css/pagination';

const CategoryDivCarousel = ({category}) => {

  console.log(typeof(category))
  const swiperRef = useRef()
 

  const navigate = useNavigate()
  const handleBtnClick=()=>{
    navigate(`/videos/${category}`)
  }


  const [videos,setVideos] = useState(null);
  const [loading,setLoading] = useState(false)
      
      const fetchData=useCallback(
          async() => {
            setLoading(true)
              try{
                  const res = await GetVideosByCategory(category);
                  console.log(res)
                  setVideos(res)
              }
              catch(err){
                  console.log(err);
                  // toast.error(err.response.message)
              }
              setLoading(false)
          },
          [category],
      )
  

       useEffect(()=>{
              fetchData()
          },[fetchData])


          const handlePlayClick=(id)=>{
            console.log('handleplay')
            navigate(`/video/${id}`)
          }
  
  


  return (
    <>
    <div className={classes.title_div}>
      <div className={classes.title_line}>
      <p className={classes.title}>{category}</p>
      <div className={classes.line}></div>
      </div>
      {
        category !== 'Explore' && 
        <div class="flex items-center justify-center w-[12rem] lg:w-[12rem] " onClick={handleBtnClick}>
  <div class="relative group">
      <button
      class="relative inline-block p-px font-semibold leading-6 text-white bg-gray-800 shadow-2xl cursor-pointer rounded-xl shadow-zinc-900 transition-transform duration-300 ease-in-out hover:scale-105 active:scale-95"
    >
      <span
        class="absolute inset-0 rounded-xl bg-gradient-to-r from-teal-400 via-blue-500 to-purple-500 p-[2px] opacity-0 transition-opacity duration-500 group-hover:opacity-100"
      ></span>

      <span class="relative z-10 block px-2 py-2 rounded-xl bg-gray-950">
        <div class="relative z-10 flex items-center space-x-0">
          <span class="transition-all duration-500 group-hover:translate-x-1">See More...</span>
          <svg
            class="w-6 h-6 transition-transform duration-500 group-hover:translate-x-1"
            data-slot="icon"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
              fill-rule="evenodd"
            ></path>
          </svg>
        </div>
      </span>
    </button>
    </div>
    </div>
      }
    </div>
    {
      loading ? 
      <Lottie 
        animationData={loader}
        height="100%"
      />
      :
      <div className={classes.slider}
      onMouseEnter={() => {
      if (swiperRef.current) {
        swiperRef.current.autoplay.stop(); // Stop autoplay on hover
        
      }
    }}
    onMouseLeave={() => {
      if (swiperRef.current) {
        swiperRef.current.autoplay.start(); // Resume autoplay on mouse leave
        
      }
    }} 
      >
    <Swiper
    ref={swiperRef}
      slidesPerView={2}
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 2000, // Time in milliseconds between slides
        disableOnInteraction: false, // Keeps autoplay running even after interaction
      }}
      // spaceBetween={10}
      className="mySwiper"
      onSwiper={(swiper) => (swiperRef.current = swiper)} 
      breakpoints={{
    // Small devices (mobile phones, 640px and down)
        576: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        // Medium devices (tablets, 768px and up)
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        // Large devices (desktops, 1024px and up)
        1024: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        // Extra large devices (1200px and up)
        1200: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      }}
      modules={[Pagination,Autoplay]}

    >
      {
        videos && videos.map((video,idx)=>(

          <SwiperSlide className={classes.slide}>
        <img src={video?.thumbnail_url} alt="" className={classes.img} loading = "lazy" />
        <button className={classes.btn} onClick={()=>{handlePlayClick(video?.id)}}><i className='pi pi-play' ></i>play</button>
      </SwiperSlide>
         
        ))
      }
      
    </Swiper>
    </div>
    }
    
  </>
  )
}

export default CategoryDivCarousel
