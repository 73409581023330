import logo from './logo.svg';
import './App.css';
import {Routes,Route} from 'react-router-dom'
import Home from './Pages/Home';
import CategoryVideo from './Pages/CategoryVideo';
import VideoPage from './Pages/VideoPage';
import Footer from './Components/Footer';

function App() {
  return (
    <>
    <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/videos/:category' element={<CategoryVideo/>} />
    <Route path='/Video/:id' element={<VideoPage/>} />
    </Routes>
    <Footer/>
    </>
  );
}

export default App;
