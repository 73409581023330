import React from 'react'

const Footer = () => {
  return (
    <div className='bg-black text-center p-6 text-white mt-10'>
    © Lifestyyl-2025. All rights reserved.
    </div>
  )
}

export default Footer
