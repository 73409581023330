import { get_all_videos, get_categories, get_video_by_id, get_videos_by_category } from "./Apis"
import axios from 'axios'

export const GetCategories=async()=>{
    try{
        const response = await axios.get(get_categories)
        return response.data
    }
    catch(err){
        console.log(err)
        throw err;
    }
}

export const GetVideos=async()=>{
    try{
        const response = await axios.get(get_all_videos)
        return response.data.videos
    }
    catch(err){
        console.log(err)
        throw err;
    }
}


export const GetVideosByCategory=async(category)=>{
    try{
        const response = await axios.get(`${get_videos_by_category}/${category}`)
        return response.data.videos
    }
    catch(err){
        console.log(err)
        throw err;
    }
}

export const GetVideoById=async(id)=>{
    try{
        const response = await axios.get(`${get_video_by_id}/${id}`)
        return response.data.video
    }
    catch(err){
        throw err
    }
}